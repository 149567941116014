<template>
  <v-layout class="d-flex flex-column">
    <v-card-title class="ma-0 pa-0 mb-4">
      <v-btn @click.stop="$router.go(-1)" icon>
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div class="title mr-2">{{ $lang("blocked") }} {{ $lang("Users") }}</div>
      <v-icon>mdi-account-cancel</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-progress-linear v-if="loading" indeterminate />
    <v-row v-if="items" dense class="mt-4 d-flex flex-row justify-center">
      <v-col cols="12" md="6" v-for="(item, idx) in items" :key="idx">
        <v-list dense one-line class="rounded-xl">
          <v-list-item class="rounded-xl">
            <v-list-item-avatar size="50">
              <v-img contain :src="item.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.display_name }}</v-list-item-title>
              <v-list-item-subtitle>
                @{{ item.username }} | {{ item.pivot.created_at | fromNow }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="handleClick(item)" icon>
                <v-icon>mdi-lock</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row v-if="items">
      <v-col cols="12">
        <query-page-redirect :pagination="pagination" />
      </v-col>
    </v-row>
    <v-row v-if="!items || items.length < 1">
      <v-col cols="12">
        <v-card-text class="text-center">
          {{
            isHindi
              ? `आपके द्वारा ब्लॉक किए हुए यूजर्स यहाँ दिखेंगे`
              : `Users block by you will show here.`
          }}
        </v-card-text>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import QueryPageRedirect from "@/components/QueryPageRedirect";
// import { Device } from "@capacitor/device";
// import { interstitial } from "@/plugins/admob/interstitial";

export default {
  name: "blocked-users",
  components: { QueryPageRedirect },
  data() {
    return {
      loading: true,
      items: [],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
      info: null,
      isAdShown: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleClick(item) {
      this.loading = true;
      axios
        .post("ugcp/unblock/user/", {
          id: item.id,
        })
        .then((res) => {
          this.items = this.items.filter((el) => el.id != item.id);
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    fetchItems(page = 1) {
      this.loading = true;
      axios
        .get("ugcp/blocked/users?orderby=id|desc&page=" + page)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        });
    },
  },
  async created() {
    this.fetchItems(this.$route.query.page);
    // this.info = await Device.getInfo();
    // if (!this.isAdShown) {
    //   if (this.info && this.info.platform != "web") {
    //     await interstitial();
    //     this.isAdShown = true;
    //   }
    // }
  },
};
</script>
